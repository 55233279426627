/* start admin login css */

.admin-auth-main-flex {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.admin-auth-left-main {
  width: 100%;
}

.admin-auth-right-main {
  width: 100%;
  max-width: 50%;
  flex: 0 0 50%;
}

.admin-auth-left-bg {
  height: 100vh;
  object-fit: cover;
  background-repeat: no-repeat;
  background-image: url(../../../assets/images/png/login-bg.jpg);
  background-size: cover;
  background-position: right;
  width: 100%;
}

.admin-auth-left-bg-main {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  height: 100vh;
  width: 50%;
}

.admin-auth-main {
  max-width: 375px;
  margin: auto;
  width: 100%;
}

.admin-auth-box {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-login-btn-main .admin-login-btn {
  margin-top: 28px;
  width: 100%;
}

.admin-checkbox-main .admin-checkbox-lable {
  color: var(--secondary-color);
  margin-left: 0px;
  margin-right: 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.admin-checkbox-main {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.admin-checkbox-main .MuiButtonBase-root {
  margin-right: 10px;
  padding: 0px;
}

.admin-checkbox-lable .MuiFormControlLabel-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.admin-auth-inner-main .admin-wel-text {
  color: var(--primary-color);
  font-size: 32px;
  line-height: normal;
  font-weight: 600;
  margin-bottom: 16px;
}

.admin-auth-inner-main .admin-sign-para {
  margin-bottom: 20px;
  color: var(--secondary-color);
}
.admin-forgot-row .admin-forgot-para {
  color: var(--selected-color);
  text-decoration: none;
  font-weight: 500;
}

/* end admin login css */

/* start or text design */

.admin-or-text {
  text-align: center;
  color: var(--secondary-color);
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  margin: 30px 0px 0px;
  position: relative;
  max-width: max-content;
  background-color: #fff;
}

.admin-condition-content {
  text-align: center;
}

.admin-condition-content .admin-condition-text {
  color: var(--secondary-color);
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}

.admin-condition-content .admin-condition-span {
  color: var(--selected-color);
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  text-decoration: none;
}

.admin-or-text-main {
  position: relative;
  margin: 30px 0px;
}

.admin-or-text-main .admin-or-cocntent-text {
  background: #f4f5f7;
  margin: auto;
  z-index: 9;
  position: relative;
  text-align: center;
  width: 30px;
  color: var(--secondary-color);
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}

.admin-or-text-main::after {
  content: "";
  width: 100%;
  height: 1px;
  background: #d3d5d6;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

/* end or text design */

/* start otp page */

.admin-otp-flex-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.admin-form-control .MuiFormControl-root {
  width: 100%;
}
.admin-otp-input-box {
  width: 100%;
}
.cursor-pointer {
  cursor: pointer;
}
/* end otp page */

/* start chnage password page css */

/* end change password page css */

.admin-form-group .admin-form-control.login-input {
  height: 40px;
}
