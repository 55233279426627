* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

html {
  scroll-behavior: smooth;
  scroll-padding-block-start: 30px;
}

body {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: var(--main-black);
  background-color: var(--body-color);
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

:root {
  --main-white: #fff;
  --primary-color: #111827;
  --secondary-color: #6b7280;
  --selected-color: #00b45d;
  --main-border: #d1d5db85;
  --light-border: #d1d5db1f;
  --main-black: #000;
  --main-red: #ff0000;
  --bg-color: #161618;
  --body-color: #f4f5f7;
  --off-white: #ffffff91;
  --dark-pink: #8c116d;
  --orange: #c85803;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.cus-scrollbar::-webkit-scrollbar {
  width: 3px;
  height: 5px;
  background-color: #ebebeb;
  border-radius: 100rem;
}

.cus-scrollbar::-webkit-scrollbar-thumb {
  background: #27447c;
  border-radius: 100rem;
}

.cus-scrollbar {
  scrollbar-color: #27447c #ebebeb;
  scrollbar-width: thin;
}

.grid-main .grid-item {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
}

.admin-text-decoration-none {
  text-decoration: none !important;
}

.admin-flex-all {
  display: flex;
  justify-content: space-between;
}

/* start input field design css */

.admin-form-group .admin-form-control input {
  border: 1px solid var(--main-border);
  border-radius: 4px;
  width: 100%;
  padding: 7px 15px;
  background-color: transparent;
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.admin-form-group .admin-form-control input::placeholder {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  opacity: 1;
}

.admin-form-group
  .admin-form-control
  .Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
  border-width: 0px;
}

.admin-form-control fieldset {
  border: 0px;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--main-border) !important;
}

.admin-form-control-textarea:focus-visible {
  outline: none !important;
}

/* end input field design css */

/* password field */

.admin-input-box .admin-form-lable {
  font-size: 12px;
  line-height: 17px;
  font-weight: 500;
  margin: 0px 0px 6px;
  color: var(--secondary-color);
}

.admin-input-box {
  margin-bottom: 15px;
}

.admin-input-box .MuiInputBase-root {
  width: 100%;
}

.admin-input-box .MuiInputBase-root fieldset:hover {
  border-color: transparent;
}

.admin-form-group .admin-form-control-eye {
  border: 1px solid var(--main-border);
}

.admin-input-box .admin-form-control-eye input {
  border: 0px;
}

.admin-input-box .admin-form-control-eye input::placeholder {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  opacity: 1;
}

/* End password field */

.admin-page-border {
  border: 1px solid var(--main-border);
  border-radius: 6px;
  padding: 20px;
}

.btn-main .btn {
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 10px 15px;
  text-transform: capitalize;
}

.btn-main-primary .btn-primary,
.btn-main-primary .btn-primary:hover {
  background-color: var(--selected-color);
  border-radius: 4px;
  color: var(--main-white);
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  padding: 10px 10px;
  text-transform: capitalize;
}

.border-btn-main .border-btn {
  border: 1px solid var(--main-border);
  border-radius: 4px;
  padding: 7px 10px;
  font-size: 12px;
  line-height: 16px;
  color: var(--secondary-color);
  font-weight: 400;
  text-transform: capitalize;
}

.admin-common-para {
  font-size: 14px !important;
  line-height: 21px !important;
  font-weight: 400;
}

/* start switch css */

.admin-switch-main {
  margin-left: 20px;
}

.admin-switch-lable .MuiTypography-root {
  font-size: 14px;
  line-height: 21px;
  color: var(--secondary-color);
  font-weight: 400;
}

/* end switch css */

/* start pagination css */

.admin-pagination-main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid var(--main-border);
  padding-top: 20px;
}

.admin-pagination-main .MuiPagination-ul {
  margin-right: 20px;
}

.admin-pagination-main .MuiPaginationItem-root {
  height: 20px;
  min-width: 20px;
  border-radius: 4px;
  padding: 0px;
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-color);
  font-weight: 400;
  margin: 0 4px;
}

.admin-pagination-main .MuiPaginationItem-root.Mui-selected:hover {
  background-color: var(--selected-color);
  color: var(--main-white);
}

.admin-pagination-main .MuiPaginationItem-root.Mui-selected {
  background-color: var(--selected-color);
  color: var(--main-white);
}

/* end pagination css */

/* start progrss bar css */

.admin-progress-bar-main {
  max-width: 470px;
  width: 100%;
  position: relative;
}

.admin-progress-bar-main .admin-progress-bar .MuiLinearProgress-bar {
  border-radius: 8px;
  background-color: var(--selected-color);
}

.admin-progress-bar-content {
  margin-top: 30px;
}

.admin-progress-bar-content .admin-page-title {
  font-size: 20px;
  line-height: 30px;
  color: var(--primary-color);
  font-weight: 500;
}

.admin-progress-bar-text {
  position: absolute;
  right: -35px;
  top: -5px;
  font-size: 14px;
  line-height: 21px;
  color: var(--secondary-color);
  font-weight: 500;
}

/* end progrss bar css */

/* start error text css */

.admin-error-text {
  color: var(--main-red);
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
}

/* end error text css */

/* / start dropdown css / */

.admin-dropdown-box .admin-form-control {
  width: 100%;
  position: relative;
}

.admin-dropdown-select .MuiSelect-select {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  display: flex;
  padding: 5.6px 15px;
  border-radius: 4px;
  border: 1px solid var(--main-border);
}

.admin-grey-down {
  position: absolute;
  top: 17.92px;
  right: 21.92px;
  height: 10px;
  width: 10px;
  object-fit: contain;
}

.admin-dropdown-box .MuiMenu-list .admin-drop-menuitem {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

/* / end dropdown css / */

.admin-row {
  grid-gap: 30px;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, 1fr);
}

/* start common modal css */
.admin-modal .admin-modal-inner {
  padding: 30px 15px;
  width: calc(100% - 30px);
  max-height: calc(100vh - 40px);
  border: 1px solid var(--main-border);
  border-radius: 10px;
}

.admin-modal-hgt-scroll {
  max-height: calc(100vh - 194px);
  overflow: auto;
  padding: 0 5px 0px 0px;
  margin-right: -5px;
}

.admin-modal-close-icon {
  cursor: pointer;
}

.MuiBackdrop-root {
  background-color: rgb(0 0 0 / 70%);
}

.admin-modal-footer {
  padding: 15px 0px 0px;
}

.admin-modal-header .modal-close-btn {
  padding: 0px;
  min-width: 30px;
  justify-content: flex-end;
}

/* end common modal css */

/* start common table design  */

.admin-table-data-flex {
  display: flex;
  align-items: center;
  width: 100%;
}

.admin-table-data-btn-flex {
  display: flex;
}

.admin-table-data-btn-flex .admin-table-data-btn {
  min-width: 30px;
  padding: 0px;
  justify-content: flex-start;
}

.admin-table-data-img {
  margin-right: 15px;
  height: 25px;
  width: 25px;
  object-fit: cover;
  border-radius: 5px;
}

.admin-table-data-flex .admin-table-data-text {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-color);
  font-weight: 400;
}

.page-table-main .table-container {
  padding-bottom: 7px;
  background-color: transparent;
  box-shadow: none;
  overflow: auto;
  max-width: calc(100vw - 0px);
}

.page-table-main .table {
  min-width: 1100px;
}

.page-table-main .table-th {
  font-size: 12px;
  line-height: 18px;
  color: var(--primary-color);
  font-weight: 500;
  padding: 10px 10px 10px 10px;
  border-bottom: 1px solid var(--main-border);
  background-color: #1b698e1f;
}

.page-table-main .table-td {
  font-size: 11px;
  line-height: 15px;
  color: var(--secondary-color);
  font-weight: 400;
  padding: 7px 10px 7px 10px;
  border-bottom: 1px solid var(--main-border);
}

.page-table-main .table-container::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}

.page-table-main .table-container::-webkit-scrollbar-track {
  background: var(--main-border);
  border-radius: 5px;
}

.page-table-main .table-container::-webkit-scrollbar-thumb {
  background: #8888885c;
}

.page-table-main .table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* end common table design  */

.admin-icon {
  height: 13px;
  width: 13px;
  object-fit: contain;
}

.card-border {
  border-radius: 5px;
  border: 1px solid var(--main-border);
  padding: 20px;
}

.common-card {
  -webkit-box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
  box-shadow: 0 5px 20px rgba(173, 181, 217, 0.03);
  background-color: var(--main-white);
  border: 1px solid var(--main-border);
  border-radius: 10px;
  padding: 15px;
}

.admin-body-overflow .MuiTooltip-tooltip {
  box-shadow: 0 5px 20px rgba(173, 181, 217, 0.03);
  background-color: var(--selected-color);
  border-radius: 5px;
  color: var(--main-white);
  padding: 4px 8px;
  font-size: 13px;
  line-height: 25px;
  font-weight: 500;
}

.admin-body-overflow .MuiTooltip-tooltip .MuiTooltip-arrow::before {
  color: var(--selected-color);
}

.admin-userlist-inner-btn-flex {
  display: flex;
  gap: 10px;
}

.admin-search-box {
  position: relative;
}

.admin-search-box .admin-form-control input::placeholder {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  opacity: 1;
}

.admin-search-grey-img {
  position: absolute;
  left: 13px;
  top: 8px;
  margin-right: 10px;
}

.admin-search-box .admin-form-group .admin-form-control input {
  padding: 6.38px 35px;
}

.admin-user-list-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.admin-userlist-btn-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* end admin css */

/* start user globle css */
.container {
  padding: 0px 50px 0px 50px;
  width: 100%;
  margin: auto;
}

.text-decoration-none {
  text-decoration: none !important;
}

.flex-all {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* start input field design css */

.form-group .form-control input {
  border: 1px solid var(--main-border);
  border-radius: 4px;
  width: 100%;
  padding: 10px 15px;
  background-color: transparent;
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.form-group .form-control input::placeholder {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  opacity: 1;
}

.form-group .form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
  border-width: 0px;
}

.form-control fieldset {
  border: 1px solid var(--main-border);
  border-radius: 2px;
}

.form-control-textarea:focus-visible {
  outline: none !important;
}

/* end input field design css */

.primary-btn-main .primary-btn,
.primary-btn-main .primary-btn:hover {
  background-color: var(--primary-color);
  border-radius: 4px;
  width: 100%;
  max-width: 380px;
  color: var(--main-white);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 10px 15px;
  text-transform: capitalize;
}

.common-para {
  font-size: 14px !important;
  line-height: 21px !important;
  font-weight: 400;
}

/* start switch css */

.switch-main {
  margin-left: 20px;
}

.switch-lable .MuiTypography-root {
  font-size: 14px;
  line-height: 21px;
  color: var(--secondary-color);
  font-weight: 400;
}

/* end switch css */

/* start pagination css */

.pagination-main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 27px;
}

.pagination-main .MuiPagination-ul {
  margin-right: 20px;
}

.pagination-main .MuiPaginationItem-root {
  height: 20px;
  min-width: 20px;
  border-radius: 4px;
  padding: 0px;
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-color);
  font-weight: 400;
  margin: 0 4px;
}

.pagination-main .MuiPaginationItem-root.Mui-selected:hover {
  background-color: var(--primary-color);
  color: var(--main-white);
}

.pagination-main .MuiPaginationItem-root.Mui-selected {
  background-color: var(--primary-color);
  color: var(--main-white);
}

/* end pagination css */

/* start progrss bar css */

.progress-bar-main {
  max-width: 470px;
  width: 100%;
  position: relative;
}

.progress-bar-main .progress-bar .MuiLinearProgress-bar {
  border-radius: 8px;
  background-color: var(--primary-color);
}

.progress-bar-content {
  margin-top: 30px;
}

.progress-bar-content .admin-page-title {
  font-size: 20px;
  line-height: 30px;
  color: #111827;
  font-weight: 400;
}

.progress-bar-text {
  position: absolute;
  right: -35px;
  top: -5px;
  font-size: 14px;
  line-height: 21px;
  color: var(--secondary-color);
  font-weight: 500;
}

/* end progrss bar css */

/* start error text css */

.error-text {
  color: var(--main-red);
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
}

/* end error text css */

/* / start dropdown css / */

.dropdown-box .form-control {
  width: 100%;
  position: relative;
}

.dropdown-select .MuiSelect-select {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  display: flex;
  padding: 10px 15px;
  border-radius: 4px;
}

.grey-down {
  position: absolute;
  top: 17.92px;
  right: 21.92px;
  height: 10px;
  width: 10px;
  object-fit: contain;
}

.dropdown-box .MuiMenu-list .drop-menuitem {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

/* / end dropdown css / */

/* start common table title */

.table-title-main .table-title-name {
  color: var(--primary-color);
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
}

.table-title-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.table-search-flex {
  display: flex;
}

/* end common table title */

/* start add user modal css */
.admin-modal .admin-add-user-modal-inner-main {
  max-width: 500px;
}

.admin-span-text {
  color: #d1d5db;
  margin-left: 3px;
}

.admin-modal .admin-modal-title {
  color: var(--primary-color);
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
}

.admin-modal-user-btn-flex {
  display: flex;
  justify-content: flex-end;
  padding-top: 3px;
}

.admin-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* end add user modal css */

/* start delete user modal css */

.admin-user-circle-img {
  width: 52px;
  height: 52px;
  object-fit: contain;
  margin: auto;
  display: flex;
}

.admin-modal .admin-delete-modal-inner-main {
  max-width: 450px;
}

.admin-delete-modal-inner-main .admin-delete-modal-title {
  color: var(--primary-color);
  font-size: 28px;
  line-height: 42px;
  font-weight: 500;
  text-align: center;
  margin: 20px 0px 16px;
}

.admin-delete-modal-inner-main .admin-delete-modal-para {
  color: var(--secondary-color);
  text-align: center;
  max-width: 304px;
  width: 100%;
  margin: auto;
}

.admin-delete-modal-btn-flex {
  display: flex;
  justify-content: center;
  margin-top: 26px;
}

.admin-delete-modal-btn-flex .admin-modal-cancel-btn {
  margin-right: 15px;
}

.admin-delete-modal-btn-flex .admin-modal-delete-btn,
.admin-delete-modal-btn-flex .admin-modal-delete-btn:hover {
  color: #ffffff;
  background-color: #e4004d;
}

.MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgb(17 24 39 / 60%);
}

/* end delete user modal css */

/* start filter css */

.admin-filter-main {
  position: relative;
}

.admin-filter-main .MuiDrawer-paper {
  width: 100%;
  min-width: 400px;
  max-width: 400px;
}

.admin-filter-header {
  background-color: #07415d;
  display: flex;
  justify-content: space-between;
  padding: 16px 25px;
  align-items: center;
}

.admin-filter-close-icon {
  height: 21px;
  width: 21px;
  object-fit: contain;
}

.admin-filter-header .admin-filter-title {
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 400;
}

.admin-filter-inner-main {
  padding: 20px 25px;
  background-color: #fff;
}

.admin-filter-btn-main {
  display: flex;
  justify-content: flex-end;
}

.admin-filter-btn-main .admin-filter-cancel-btn {
  margin-right: 15px;
}

.admin-filter-footer {
  border-top: 1px solid #d1d5db;
  padding: 15px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
  right: 0px;
}

.admin-filter-checkbox-main .admin-checkbox-lable {
  margin-right: 20px;
}

/* end filter css */

.admin-down-icon,
.admin-plus-icon {
  margin-right: 10px;
}

.admin-page-title-main .admin-page-title {
  color: var(--primary-color);
  font-size: 17px;
  line-height: 24px;
  font-weight: 600;
  /* position: relative;
      padding-left: 10px; */
}

/* .admin-page-title-main .admin-page-title::after {
      content: '';
      position: absolute;
      height: 22px;
      width: 4px;
      background-color: var(--selected-color);
      left: 0px;
} */

.admin-sub-title-main .admin-sub-title {
  color: var(--primary-color);
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}

.chart-row-main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 15px;
}

.pie-chart-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.chart-title-main .chart-title {
  font-size: 13px;
  font-weight: 600;
  line-height: 19px;
  color: var(--bg-color);
  margin-bottom: 10px;
}

.admin-sub-title-main .admin-sub-title {
  color: var(--bg-color);
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  position: relative;
}

.admin-sub-title-main .admin-sub-title::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 35px;
  background-color: var(--bg-color);
  left: 0px;
  bottom: -2px;
}

.title-row-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px;
}

.page-table-main .table-not-found-td {
  padding: 0px;
  border: 0px;
}

.data-not-found-main {
  box-shadow: 0 5px 20px rgba(173, 181, 217, 0.03);
  background-color: var(--main-white);
  border: 1px solid var(--main-border);
  border-radius: 5px;
  padding: 9px;
  width: 100%;
  margin: 10px 0px 0px;
}

.data-not-found-main .data-not-found-text {
  font-size: 12px;
  line-height: 18px;
  color: var(--primary-color);
  font-weight: 500;
  text-align: center;
}

/* start loader css */
.loader-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 255px);
}

.loader {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0px;
  text-align: left;
}

.loader span {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: var(--bg-color);
  -webkit-animation: loader 1.5s linear infinite;
  animation: loader 1.5s linear infinite;
}

.loader span:last-child {
  animation-delay: -0.9s;
  -webkit-animation-delay: -0.9s;
}

@keyframes loader {
  0% {
    transform: scale(0, 0);
    opacity: 0.8;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.8;
  }

  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}

/* end loader css */

/* start select 2 css */
.admin-select2-main {
  width: 300px;
}

.admin-select2-main .select2-container--default .select2-selection--multiple {
  background-color: transparent;
  border: 1px solid var(--main-border);
}

.admin-select2-main
  .select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border: 1px solid var(--main-border);
  background-color: transparent;
}

.admin-select2-main
  .select2-container
  .select2-search--inline
  .select2-search__field {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.admin-select2-main
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  padding: 1px 5px 0px;
}

.admin-select2-main
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: transparent;
  border: 1px solid var(--main-border);
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

/* end select 2 css */

/* start accordian design  */

.accordian-main .accordian-summary {
  background-color: var(--light-gray);
  min-height: 30px;
}

.accordian-main .accordian {
  margin-bottom: 10px;
  box-shadow: none;
}

.accordian-main .accordian-details {
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-color);
  font-weight: 400;
  border: 1px solid var(--main-border);
}

.accordian-main .MuiAccordion-root::before {
  background-color: transparent;
}

.accordian-main .MuiAccordionSummary-content.Mui-expanded {
  margin: 5px 0px;
}

.accordian-main .MuiAccordionSummary-content {
  font-size: 12px;
  line-height: 18px;
  color: var(--primary-color);
  font-weight: 500;
  margin: 5px 0px;
}

.accordian-main .accordian:last-child {
  margin-bottom: 0px;
}

.accordian-main .accordian.Mui-expanded {
  margin: 10px 0;
}

.accordian-main .accordian-summary.Mui-expanded {
  min-height: 30px;
  padding: 5px 16px;
}

/* end accordian design */

img[alt] {
  font-size: 10px;
}

.street-bet-common-box {
  /* background-color: #fff;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 20px; */
}

.dashboard-text p,
.title-main .page-title {
  color: #000;
  font-size: 19px;
  line-height: 20px;
  font-family: "inter-bold", sans-serif;
  font-weight: bold;
}

.title-header {
  align-items: center;
  display: flex;
}

.title-header-flex {
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
}

.table {
  background-color: #fff;
}

.table-container {
  box-shadow: none !important;
}

.table-design-main {
  background: #f0f3f8;
  border-collapse: initial !important;
  border-radius: 0 !important;
  /* border-spacing: 0 8px !important; */
}

/* .table-design-main tr td:first-child,
.table-design-main tr th:first-child {
  border-radius: 10px 0 0 10px;
} */

.table-design-main tr td:first-child,
.table-design-main tr th:first-child {
  padding-left: 20px !important;
}

.table-design-main tr th {
  font-family: inter-semibold, sans-serif;
  background: #00b45d;
  padding: 7px 14px !important;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.invoice-table tr td {
  background: #fff;
  display: table-cell;
  text-align: left;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 500;
  border-bottom: 1px dotted #ededed;
}

.invoice-table tr td li {
  list-style-type: none;
}

.invoice-table tr td {
  white-space: nowrap;
}

.cms-page-content p {
  font-size: 16px;
}

/* new CSS */

.dashboard-icon-box .admin-card-img-main {
  width: 100%;
}

.dashboard-icon-box .admin-card-img-main .admin-card-img {
  width: 100%;
}

.dashboard-icon-box .admin-card-box.common-card {
  padding: 0;
}
.pagination-design {
  margin-top: 15px;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.pagination-loader {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.pagination-count .MuiSelect-select {
  padding: 5px 10px 5px 10px;
  font-size: 14px;
  line-height: 20px;
}

.input-design-div input {
  padding: 10px 20px;
  font-size: 13px;
  font-family: 'Poppins';
}

.MuiPagination-ul .MuiPaginationItem-root.Mui-selected {
  background: #00b45d42;
}

.MuiPagination-ul .MuiPaginationItem-root {
  font-size: 13px;
}