.social-main .social-ul {
      padding: 0px;
      margin: 20px 0px;
      display: flex;
      justify-content: center;
      gap: 20px;
}

.social-main .social-li {
      padding: 0px;
      margin: 0px;
      width: 50px;
}

.social-main .social-box {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background-color: var(--main-border);
      display: flex;
      justify-content: center;
      align-items: center;
}

.social-main .social-icons {
      height: 20px;
      width: 20px;
      object-fit: contain;
}




.user-auth-main {
      /* background-color: #151b3c; */
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      /* background-image: url(./../../../assets/images/video/bg-1.mp4); */
      /* // background-image: url(./../../../assets/images/png/bg1.jpg);
      // background-image: url(./../../../assets/images/png/bg2.jpg);
      // background-image: url(./../../../assets/images/png/bg3.jpg);
      // background-image: url(./../../../assets/images/png/bg4.jpg); */
      background-size: cover;
      width: 100%;
      background-repeat: no-repeat;
      position: relative;
}

.auth-video {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
}

.user-auth-card-main {
      margin: auto;
      position: relative;
      max-width: 450px;
      min-width: 450px;
      background-color: #ffffff05;
      border-radius: 10px;
      backdrop-filter: blur(10px);
      border: 2px solid #8b84841a;
      box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
      padding: 30px;
}

.user-auth-card-main .user-auth-title {
      font-size: 22px;
      line-height: 26px;
      color: var(--main-white);
      font-weight: 600;
      margin-bottom: 20px;
}

.user-auth-card-main .user-auth-para {
      font-size: 14px;
      line-height: 17px;
      color: var(--off-white);
      font-weight: 400;
      margin-bottom: 20px;
}


.user-form-group .MuiInputAdornment-root svg {
      color: #ffffff73;
}


.user-login-btn-main {
      margin-top: 40px;
}

.btn-main-primary.user-login-btn-main .user-login-btn,
.btn-main-primary.user-login-btn-main .user-login-btn:hover {
      width: 100%;
      /* background-color: var(--dark-pink); */
}


.user-condition-content {
      margin: 30px 0px 0px;
}

.user-condition-content .user-condition-text {
      font-size: 13px;
      line-height: 20px;
      color: var(--secondary-color);
      font-weight: 400;
      margin-bottom: 20px;
      text-align: center;
}

.user-condition-content .user-condition-span {
      font-size: 13px;
      line-height: 20px;
      color: var(--main-white);
      font-weight: 400;
      margin-bottom: 20px;
      text-decoration: none;
}


.user-checkbox-main {
      display: flex;
      align-items: center;
      gap: 10px;
}

.user-checkbox-main .MuiCheckbox-root {
      padding: 0px;
}

.user-checkbox-lable {
      font-size: 14px;
      line-height: 17px;
      color: var(--off-white);
      font-weight: 400;
      margin-left: 10px;
}


.user-forgot-row .user-forgot-para {
      font-size: 14px;
      line-height: 17px;
      color: var(--off-white);
      font-weight: 400;
      text-decoration: none;
}


.user-otp-flex-main {
      margin-bottom: 25px;
}

.user-forgot-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
}

.user-input-box {
      margin-bottom: 20px;
}

.user-input-box .MuiInputBase-root {
      width: 100%;
}

.user-input-box .MuiInputBase-root fieldset:hover {
      border-color: transparent;
}

.user-form-group .user-form-control-eye {
      border: 1px solid var(--light-border);
}

.user-form-group .user-form-control.user-form-control-eye input {
      border: 0;
}

.user-input-box .user-form-control-eye input::placeholder {
      color: var(--secondary-color);
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
      opacity: 1;
}

.user-input-box {
      margin-bottom: 20px;
}

.user-input-box .user-form-lable {
      font-size: 12px;
      line-height: 17px;
      color: var(--off-white);
      font-weight: 400;
      margin-bottom: 5px;
}

.user-form-group .user-form-control input {
      border: 1px solid var(--light-border);
      border-radius: 4px;
      width: 100%;
      padding: 10px 15px;
      background-color: transparent;
      color: var(--off-white);
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
}

.user-form-group .user-form-control input::placeholder {
      color: var(--off-white);
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      opacity: 1;
}

.user-form-group .user-form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
      border-width: 0px;
}

.user-form-control fieldset {
      border: 0px;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: var(--light-border) !important;
}

.user-form-control-textarea:focus-visible {
      outline: none !important;
}

.user-checkbox-main .user-checkbox-lable {
      font-size: 14px;
      line-height: 17px;
      color: var(--off-white);
      font-weight: 400;
}