@media (max-width: 1249px) {}

@media (max-width: 1023px) {
      .admin-user-card-row {
            grid-template-columns: repeat(2, 1fr);
      }
}

@media (max-width: 767px) {
      .card-img {
            width: 50px;
            height: 50px;
      }

      .admin-card-box .card-user-title {
            font-size: 15px;
            line-height: 18px;
      }

      .card-data-flex {
            margin: 5px 0px;
      }

      .admin-user-card-row {
            grid-template-columns: repeat(1, 1fr);
      }
}

@media (max-width: 549px) {}

@media (max-width: 374px) {}