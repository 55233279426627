@media (max-width: 1249px) {}

@media (max-width: 1023px) {
      .admin-body-overflow {
            overflow: hidden;
      }
      .admin-user-list-flex {
            align-items: flex-start;
            flex-direction: column;
      }
}

@media (max-width: 767px) {
      .admin-admin-dashboard-inner-box {
            max-width: 100%;
      }

      .admin-dashboard-content .admin-user-list-page-title {
            margin-bottom: 10px;
      }

      .admin-filter-main .MuiDrawer-paper {
            min-width: 300px;
            max-width: 300px;
      }

      .admin-progress-bar-text {
            right: 0px;
            top: -23px;
      }
}

@media (max-width: 549px) {}

@media (max-width: 374px) {}